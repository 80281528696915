export const API_ACTS_MODEL = {
    entity: 'acts',
    url: 'acts/',
    methods: {
        municipalities:{
            url: 'municipalities/'
        },
        buildingTypes:{
            url: 'building-types/'
        },
        damageTypes:{
            url: 'damage-types/'
        },
        damageNames:{
            url: 'damage-names/'
        },
        signing:{
            url: 'signing/'
        },
        pdf:{
            url: 'pdf/'
        },
        getDownloadPdf:{
            url: 'download-file/'
        },
        uploadPdf:{
            url: 'upload_pdf/'
        },
        getAddress:{
            url: 'get-address/'
        },
        sendSign:{
            url: 'send-sign/'
        },
    },
}