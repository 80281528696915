import React, {FC, ReactElement, useEffect, useState} from "react";
import {
    PDFDownloadLink,
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    Font,
    Image,
} from "@react-pdf/renderer";
import {ActPdfDataResponseType} from "../types";
import apiConfig from "../../../api/apiConfig";
import {formatDateIntlTimeDate} from "../../../components/UI/functions/functions";

// Подключаем кириллический шрифт
Font.register({
    family: "Roboto",
    src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf",
});

// Создаем стили для нашего PDF-документа
const styles = StyleSheet.create({
    page: {
        fontFamily: "Roboto",
        flexDirection: "row",
        backgroundColor: "#E4E4E4",
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
        paddingHorizontal: 44,
        paddingVertical: 40,
        flexDirection: "column",
        justifyContent: "space-between",
    },
});


// Создаем компонент PDF-документа
export default function getActDocument({pdfData}: { pdfData: ActPdfDataResponseType }): ReactElement {
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    <View>
                        <Text
                            style={{fontSize: 22}}
                        >{`Акт осмотра ${pdfData?.building_type}, поврежденного в результате чрезвычайной ситуации`}</Text>
                        <Text
                            style={{
                                color: "#2970FF",
                                marginTop: 12,
                                fontSize: 22,
                            }}
                        >{`№${pdfData?.number}`}</Text>

                        <View
                            style={{
                                flexDirection: "row",
                                justifyContent: "space-between",
                                marginTop: 22,
                            }}
                        >
                            <View>
                                <Text
                                    style={{
                                        color: "#667085",
                                        fontSize: 12,
                                        fontWeight: 400,
                                    }}
                                >
                                    Муниципальное образование
                                </Text>
                                <Text
                                    style={{fontSize: 12, fontWeight: 400}}
                                >{`город ${pdfData.municipality}`}</Text>
                            </View>
                            <View style={{width: 295}}>
                                <Text
                                    style={{
                                        color: "#667085",
                                        fontSize: 12,
                                        fontWeight: 400,
                                    }}
                                >
                                    тип
                                </Text>
                                <Text
                                    style={{fontSize: 12, fontWeight: 400}}
                                >{`${pdfData?.building_type}`}</Text>
                            </View>
                        </View>

                        {pdfData?.victim && (
                            <View
                                style={{
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    marginTop: 22,
                                }}
                            >
                                <View style={{width: 560}}>
                                    <Text
                                        style={{
                                            color: "#667085",
                                            fontSize: 12,
                                            fontWeight: 400,
                                        }}
                                    >
                                        ФИО собственника помещения/представителя
                                    </Text>
                                    <Text
                                        style={{fontSize: 12, fontWeight: 400}}
                                    >{`${pdfData?.victim?.last_name} ${pdfData?.victim?.first_name} ${pdfData?.victim?.patronymic}`}</Text>
                                </View>
                                <View style={{width: 295}}>
                                    <Text
                                        style={{
                                            color: "#667085",
                                            fontSize: 12,
                                            fontWeight: 400,
                                        }}
                                    >
                                        Контактный телефон
                                    </Text>
                                    <Text
                                        style={{fontSize: 12, fontWeight: 400}}
                                    >{`+7${pdfData?.victim?.phone_number}`}</Text>
                                </View>
                            </View>
                        )}
                        <View
                            style={{
                                flexDirection: "row",
                                justifyContent: "space-between",
                                marginTop: 22,
                            }}
                        >
                            <View>
                                <Text style={{color: "#667085", fontSize: 12}}>
                                    Адрес объекта
                                </Text>
                                <Text
                                    style={{fontSize: 12, fontWeight: 200}}
                                >{`${pdfData?.address}`}</Text>
                            </View>
                            <View>
                                <Text style={{color: "#667085", fontSize: 12}}>
                                    Дата обстрела
                                </Text>
                                <Text
                                    style={{fontSize: 12, fontWeight: 200}}
                                >{`${
                                    pdfData?.damage_date.split('-').reverse().join('.')
                                }`}</Text>
                            </View>
                        </View>
                        <View style={{marginTop: 24}}>
                            <Text
                                style={{fontSize: 16}}
                            >{`Настоящий акт составлен о том, что по состоянию на ${pdfData?.damage_date.split('-').reverse().join('.')}, выявлены следующие повреждения: `}</Text>
                        </View>

                        <View style={{marginTop: 12}}>
                            {pdfData?.damages?.map((item: any, index: number) => {
                                return (
                                    <>
                                        <Text style={{fontSize: 12}} key={index}>{`${
                                            index + 1
                                        }. ${item?.damage_type}`}</Text>
                                        {item.damage_images.map((item: any) =>
                                            <Image src={`${apiConfig.baseUrlMedia}${item.file}`}
                                                   style={{maxHeight: '400px', width: 'auto', height: 'auto'}}/>
                                        )}

                                        <Text style={{fontSize: 12}}>Количество: {item?.count}</Text>
                                        <Text style={{fontSize: 12}}>Примечание: {item?.note}</Text>
                                    </>

                                );
                            })}
                        </View>
                        <View
                            style={{
                                width: "100%",
                                backgroundColor: "#D0D5DD",
                                height: 2,
                                marginVertical: 20,
                            }}
                        ></View>

                        <View style={{marginBottom: 24}}>
                            <Text style={{fontSize: 12, marginBottom: 8}}>
                                Подписи лиц, проводивших осмотр помещения
                            </Text>
                            <Text
                                style={{fontSize: 16, marginBottom: 4}}
                            >{`${pdfData?.employee?.last_name} ${pdfData?.employee?.first_name} ${pdfData?.employee?.patronymic}`}</Text>
                            <Text style={{fontSize: 12, color: "#667085"}}>
                                Подписано через систему АИС «Контроль повреждений»
                            </Text>
                        </View>
                        {pdfData?.victim && (
                            <>
                                <View style={{marginBottom: 24}}>
                                    <Text
                                        style={{
                                            fontSize: 12,
                                            fontWeight: "light",
                                            marginBottom: 8,
                                        }}
                                    >
                                        Подпись лица, присутствующего при осмотре жилого помещения
                                        (собственник/представитель)
                                    </Text>
                                    <Text
                                        style={{fontSize: 16, marginBottom: 4}}
                                    >{`${pdfData?.victim?.last_name} ${pdfData?.victim?.first_name} ${pdfData?.victim?.patronymic}, +7${pdfData?.victim?.phone_number}`}</Text>
                                    {pdfData.act_images.length !== 0 ? (
                                        <Text
                                            style={{fontSize: 12, color: "#667085"}}
                                        >{`Подписано актом через систему АИС «Контроль повреждений» ${
                                            pdfData.signed_at &&
                                            formatDateIntlTimeDate(pdfData.signed_at || "")
                                        } Подписание`}</Text>
                                    ) : (
                                        <Text
                                            style={{fontSize: 12, color: "#667085"}}
                                        >{`Подписано СМС-сообщением через систему АИС «Контроль повреждений» ${
                                            pdfData.signed_at &&
                                            formatDateIntlTimeDate(pdfData.signed_at || "")
                                        }`}</Text>
                                    )}
                                </View>
                                <View style={{marginBottom: 8}}>
                                    <Text
                                        style={{
                                            fontSize: 12,
                                            fontWeight: "light",
                                            marginBottom: 8,
                                        }}
                                    >
                                        Дополнительный контакт
                                    </Text>
                                    {pdfData?.victim.additional_contacts.map((item: any) => {
                                        return (
                                            <Text
                                                style={{fontSize: 16, marginBottom: 4}}
                                            >{`${item.last_name} ${item.first_name} ${item.patronymic}, +7${item.phone_number}`}</Text>
                                        );
                                    })}
                                </View>
                            </>
                        )}
                    </View>
                    <View
                        style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <Text style={{fontSize: 12}}>
                            Акт создан в АИС «Контроль повреждений»
                        </Text>
                        <Text
                            style={{fontSize: 12}}
                            render={({pageNumber, totalPages}) =>
                                `Страница ${pageNumber} из ${totalPages}`
                            }
                        />
                    </View>
                </View>
            </Page>

            {pdfData?.act_images?.map((item: any, index: number) => {
                return (
                    <Page size="A4" style={styles.page} key={`${index}`}>
                        <View style={styles.section}>
                            <Text style={{fontSize: 12, marginBottom: 8}}>{`Приложение №${
                                index + 1
                            } к акту ${pdfData.number} от ${
                                pdfData.signed_at &&
                                formatDateIntlTimeDate(pdfData.signed_at || "")
                            }`}</Text>
                            <View
                                style={{
                                    flexDirection: "column", // Changed to column to ensure all elements are in one column
                                    justifyContent: "space-between",
                                    height: "85%", // Adjusted height to ensure proper spacing
                                }}
                            >
                                <Image
                                    key={`${index}`}
                                    style={{
                                        width: "100%", // Ensure full width
                                        height: 600, // Adjusted height to ensure proper spacing
                                        marginBottom: 8,
                                        objectFit: "cover",
                                    }}
                                    src={`${apiConfig.baseUrlMedia}${item.file}`}
                                />
                            </View>
                            <View
                                style={{
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    marginTop: 8, // Added margin top for spacing
                                }}
                            >
                                <Text style={{fontSize: 12}}>
                                    Акт создан в АИС «Контроль повреждений»
                                </Text>
                                <Text
                                    style={{fontSize: 12}}
                                    render={({pageNumber, totalPages}) =>
                                        `Страница ${pageNumber} из ${totalPages}`
                                    }
                                />
                            </View>
                        </View>
                    </Page>
                );
            })}
        </Document>
    )
};

