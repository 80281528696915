import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import ActsApiRequest from "../../../api/Acts/Acts";
import UserApiRequest from "../../../api/User/Users";
import {decryptData, formatDateIntlTimeDate} from "../../../components/UI/functions/functions";
import Skeleton from "react-loading-skeleton";
import Buttons from "../../../components/Buttons/Buttons";
import {ActGetResponseType, ActPdfDataResponseType, UserDataResponseType} from "../types";
import {pdf} from "@react-pdf/renderer";
import {getActDocument} from "../lib";
import icons from "../../../assets/icons/icons";

export default function GenerateActPage() {
    const params = useParams();
    const {id} = params;
    const actApi = new ActsApiRequest();
    const [loading, setLoading] = useState(false);
    const [actPdfData, setActPdfData] = useState<ActPdfDataResponseType>()
    const [isLoading, setIsLoading] = useState<boolean>(false)

    useEffect(() => {
        const getData = async () => {
            setLoading(true)
            const responsePdfAct = await actApi.getPdf(String(id) + '/')
            if (responsePdfAct.success) {
                const responsePdfData: ActPdfDataResponseType = responsePdfAct.data as ActPdfDataResponseType
                setActPdfData(responsePdfData)
                setLoading(false)
            }
        }
        getData()
    }, [])

    const generatePdf = async () => {
        if (actPdfData) {
            setIsLoading(true)
            const docInstance = pdf(getActDocument({pdfData: actPdfData}))
            const blob = await docInstance.toBlob();
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a')
            link.href = url
            link.download = 'document.pdf'
            setIsLoading(false)
            link.click()
            URL.revokeObjectURL(url)
        }
    };

    return (
        <section className="section">
            <div className="containerPageInside">
                <div>
                    {loading ? (
                        <Skeleton borderRadius={8} height={90}/>
                    ) : (
                        <>
                            <div className="contaiinerInfoAct">
                                <h1 className="titleSlide">{`Акт ${actPdfData?.number}`}</h1>
                            </div>
                            <Buttons
                                text="Сгенерировать акт в PDF"
                                ico={isLoading ? icons.load : ''}
                                onClick={() => {
                                    generatePdf();
                                }}
                            />
                        </>
                    )}
                </div>
            </div>
            {/*<div className="containerButton">*/}
            {/*    <Buttons*/}
            {/*        ico={icons.arrowLeft}*/}
            {/*        text={"Назад"}*/}
            {/*        className="sliderButton"*/}
            {/*        onClick={() => {*/}
            {/*            navigate(-1);*/}
            {/*        }}*/}
            {/*    />*/}
            {/*</div>*/}
        </section>
    );
};